import Link from 'next/link';
import { useRef, useState } from 'react';

// import dayjs from 'dayjs'
import Flicking, { FlickingError } from '@egjs/react-flicking';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import Img from '@/components/common/Img';
import { MainBanner } from '@/types/banner';
import useInterval from '@/utils/hooks/useInterval';
interface Props {
  banners: MainBanner[];
}
function AutoSlider({
  banners
}: Props) {
  const {
    t
  } = useTranslation('common');
  const [flickingIndex, setFlickingIndex] = useState(0);
  const flickingRef = useRef<Flicking>(null);
  const resetInterval = useInterval(() => {
    if (flickingRef.current) {
      flickingRef.current.next().catch(error => {
        if (error instanceof FlickingError) {
          return;
        }
      });
    }
  }, 7000);
  const handleArrowClick = (isNext: boolean) => () => {
    if (flickingRef.current) {
      resetInterval();
      if (isNext) {
        flickingRef.current.next().catch(error => {
          if (error instanceof FlickingError) {
            return;
          }
        });
        /** useInterval 초기화 */
      } else {
        flickingRef.current.prev().catch(error => {
          if (error instanceof FlickingError) {
            return;
          }
        });
      }
    }
  };
  return <div className="!relative aspect-[5/4] w-full md:aspect-[5/3] bg-gray-300" data-sentry-component="AutoSlider" data-sentry-source-file="AutoSlider.tsx">
      <FontAwesomeIcon className="absolute right-2 top-1/2 z-[3] -translate-y-1/2 cursor-pointer text-[2rem] text-white opacity-50 transition-opacity hover:opacity-100" icon={faChevronRight} onClick={handleArrowClick(true)} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="AutoSlider.tsx" />
      <FontAwesomeIcon className="absolute left-2 top-1/2 z-[3] -translate-y-1/2 cursor-pointer text-[2rem] text-white opacity-50 transition-opacity hover:opacity-100" icon={faChevronLeft} onClick={handleArrowClick(false)} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="AutoSlider.tsx" />
      <div className="absolute bottom-0 right-0 z-[2] p-4">
        <div className="rounded-full text-[9px]" style={{
        padding: '4px 8px 3px 8px',
        background: 'rgba(255, 255, 255, 0.27)'
      }}>
          <span style={{
          fontWeight: 700
        }}>{flickingIndex + 1}</span>
          <span style={{
          margin: '0 2px',
          opacity: '0.4'
        }}>/</span>
          <span style={{
          opacity: '0.4'
        }}>{banners.length}</span>
        </div>
      </div>
      <Flicking ref={flickingRef} autoInit circular hideBeforeInit horizontal preventClickOnDrag deceleration={0.003} duration={700} easing={x => Math.sqrt(1 - Math.pow(x - 1, 2))} interruptable={false} moveType="snap" onChanged={({
      currentTarget: {
        index: nextIndex
      }
    }) => {
      setFlickingIndex(nextIndex);
    }} data-sentry-element="Flicking" data-sentry-source-file="AutoSlider.tsx">
        {banners.map(({
        cover,
        tags,
        title,
        description,
        link
      }, index) => <div key={title} className="relative w-full">
            <Img className="aspect-[5/4] w-full bg-center object-cover md:aspect-[5/3]" src={cover.url} title={title} />
            (
            <Link className="absolute inset-0 p-4" href={link} style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'flex-end',
          minHeight: 32,
          color: 'white',
          backgroundImage: 'linear-gradient(to bottom, transparent, transparent, transparent, transparent, rgba(0,0,0,0.5),  rgba(0,0,0,0.7), #000000)'
        }}>
              {index === flickingIndex && <div className="fadeInUp w-full">
                  <div className="flex gap-1">
                    {tags.map(tag => <div key={tag} style={{
                border: '0.5px solid #ffffff',
                borderRadius: 3,
                padding: '4px 5px 3px 5px',
                marginBottom: 8,
                fontSize: '9px'
              }}>
                        {t(tag)}
                      </div>)}
                  </div>
                  <div style={{
              fontSize: '1.2rem',
              fontWeight: 900,
              marginBottom: '0.3rem'
            }}>
                    {title}
                  </div>
                  <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}>
                    <p style={{
                flexBasis: 'calc(85% + 3px)',
                color: 'rgba(255,255,255, 0.7)',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}>
                      {description}
                    </p>
                  </div>
                </div>}
            </Link>
            )
          </div>)}
      </Flicking>
    </div>;
}
export default AutoSlider;