import { ReactNode } from 'react';
import Img from '@/components/common/Img';
interface Props {
  image?: string;
  title: ReactNode;
  extra?: ReactNode;
  transparent?: boolean;
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
}
function Subtitle({
  image,
  title,
  extra,
  transparent = false,
  children,
  className,
  style,
  id
}: Props) {
  const render = () => <>
      <div className={`flex w-full items-center justify-between rounded-md ${children ? 'mb-2' : ''} ${transparent ? '' : 'bg-white px-3 py-2 shadow'} ${className}`} style={{
      border: transparent ? undefined : '0.3px solid rgba(0,0,0,0.1)',
      ...style
    }}>
        <div className="mr-2 !flex w-full items-center  justify-center">
          {image && <Img alt="header" className="h-6 w-6" src={image} />}
          <h2 className={`ckk w-full text-base ${image ? 'ml-2' : ''}`}>
            {title}
          </h2>
        </div>
        <div>{extra}</div>
      </div>
      {children && <div className={`flex w-full items-center justify-between rounded-md ${transparent ? 'pb-3' : 'bg-white p-3 shadow'}`}>
          {children}
        </div>}
    </>;
  return id ? <a className="my-0 w-full" href={id ? `#${id}` : '#'} id={id}>
      {render()}
    </a> : <div className="my-0 w-full" id={id}>
      {render()}
    </div>;
}
export default Subtitle;