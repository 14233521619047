import { useCallback, useEffect, useRef } from 'react';
const useInterval = (callback, delay) => {
  const savedCallback = useRef(callback);
  const intervalIdRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      intervalIdRef.current = setInterval(tick, delay);
    }
    const id = intervalIdRef.current;
    return () => {
      clearInterval(id);
    };
  }, [delay]);
  useEffect(() => {
    const id = intervalIdRef.current;
    return () => {
      clearInterval(id);
    };
  }, []);
  const resetInterval = useCallback(() => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(savedCallback.current, delay);
  }, [delay]);
  return resetInterval;
};
export default useInterval;